<template>
  <div class="flex justify-center space-y-4 py-4">
    <div class="flex justify-center text-2xl text-blue">
      <div class="font-bold uppercase">
        {{ $t("ratings.thanks") }}
      </div>
    </div>
    <div class="flex justify-center text-gray-400">
      {{ $t("ratings.rating-received") }}
    </div>
    <div
      class="flex justify-center text-xl py-2 text-blue uppercase font-semibold"
    >
      {{ $t("ratings.come-again") }}
    </div>
  </div>
</template>

<script>
export default {
  name: "RatingFinish",
};
</script>

<style></style>
