let available = false;

function isAvailable() {
  return available;
}

async function load(pixelId, router) {
  if (typeof fbq === "undefined") {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
    let ids = pixelId.split(",");
    ids.forEach((id) => {
      fbq("init", id);
    });
  }
  trackPageViews(router);
  available = true;
}

function trackPageViews(router) {
  if (!available) return;
  fbq("track", "PageView");
  router.afterEach(() => {
    fbq("track", "PageView");
  });
}

async function signupComplete() {
  if (!available) return;
  fbq("track", "CompleteRegistration");
}

export default {
  isAvailable,
  signupComplete,
  load,
};
