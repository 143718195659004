<template>
  <div class="w-full">
    <div
      class="text-blue flex items-center"
      :class="{ 'cursor-pointer': !disabled }"
      @click="toggle"
    >
      <c-icon
        name="KeyboardArrowDownRound"
        class="mr-2 flex-shrink-0 transition-all"
        :class="{ 'transform rotate-180': isOpen }"
        v-if="withArrow"
      />
      <slot name="trigger" :open="isOpen" :toggle="toggle" />
    </div>
    <c-transition-expand>
      <div v-show="isOpen">
        <slot :toggle="toggle" />
      </div>
    </c-transition-expand>
  </div>
</template>

<script>
import CIcon from './CIcon.vue'
import CTransitionExpand from './CTransitionExpand.vue'

export default {
  name: 'CCollapse',
  props: {
    open: {
      type: Boolean,
      default: false
    },
    withArrow: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: this.open
    }
  },
  watch: {
    open(value) {
      this.isOpen = value
    }
  },
  methods: {
    toggle() {
      if (this.disabled) {
        return
      }
      this.isOpen = !this.isOpen
      this.$emit('update:open', this.isOpen)
      this.$emit(this.isOpen ? 'open' : 'close')
    }
  },
  components: {
    CIcon,
    CTransitionExpand
  }
}
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.grow-enter-active,
.grow-leave-active {
  transition: max-height 0.5s;
}
.grow-enter,
.grow-leave-to {
  max-height: 0;
}
</style>
