<template>
  <div class="">
    <signup-form v-if="shouldShowSignup" />
    <login-form v-else-if="shouldShowLogin" />
    <div v-else-if="shouldShowEarnPoints" class="">
      <div
        class="bg-white rounded-full text-center py-2 w-3/4 m-auto mt-40 shadow-lg"
        @click="goToSignupOrLogin"
      >
        Sumar puntos con el pedido 🎁
      </div>
      <div
        class="bg-white rounded-full text-center py-2 w-3/4 m-auto mt-10 shadow-lg"
        @click="handleContinueWithoutSignup"
      >
        No quiero sumar puntos
      </div>
    </div>
    <div v-else class="">
      <div
        class="bg-white rounded-full text-center py-2 w-3/4 m-auto mt-40 shadow-lg"
        @click="goToSignupSelected"
      >
        Crear cuenta nueva
      </div>
      <div
        class="bg-white rounded-full text-center py-2 w-3/4 m-auto mt-10 shadow-lg"
        @click="goToLoginSelected"
      >
        Ya tengo cuenta
      </div>
    </div>
  </div>
</template>

<script>
import SignupForm from "./SignupForm.vue";
import LoginForm from "./LoginForm.vue";
import { mapState } from "pinia";
import { authStore } from "@/stores/auth";

export default {
  name: "SignupPage",
  data() {
    return {};
  },
  computed: {
    ...mapState(authStore, ["branding"]),
    shouldShowEarnPoints() {
      return this.$route.query?.lastappUrl && !this.$route.query.signupOrLogin;
    },
    shouldShowSignup() {
      return (
        (!this.$route.query?.lastappUrl && !this.$route.query?.addPoints) ||
        !!this.$route.query.signupSelected
      );
    },
    shouldShowLogin() {
      return (
        (!this.$route.query?.lastappUrl && !this.$route.query?.addPoints) ||
        !!this.$route.query.loginSelected
      );
    },
  },
  methods: {
    goToSignupSelected() {
      this.$router.push({
        query: {
          ...this.$route.query,
          signupSelected: true,
        },
      });
    },
    goToLoginSelected() {
      this.$router.push({
        query: {
          ...this.$route.query,
          loginSelected: true,
        },
      });
    },
    goToSignupOrLogin() {
      this.$router.push({
        query: {
          ...this.$route.query,
          signupOrLogin: true,
        },
      });
    },
    handleContinueWithoutSignup() {
      window.location = this.$route.query.lastappUrl;
    },
  },
  components: {
    SignupForm,
    LoginForm,
  },
};
</script>
