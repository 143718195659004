<template>
  <div class="w-full flex flex-col items-center">
    <div class="w-11/12">
      <n-form ref="formRef">
        <n-form-item label="Email o número de teléfono">
          <n-input
            @keydown.enter.prevent
            v-model:value="emailOrPhoneNumber"
            placeholder="Escribe tu email o tu número aquí"
          />
        </n-form-item>
      </n-form>
      <div class="mb-5 flex flex-row justify-center floatting bottom-0">
        <n-button
          type="primary"
          size="large"
          @click="login"
          :disabled="emailOrPhoneNumber.length === 0"
          >Acceder a la cuenta</n-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "pinia";
import { authStore } from "@/stores/auth";

export default {
  name: "LoginForm",
  data() {
    return {
      emailOrPhoneNumber: "",
    };
  },
  computed: {
    ...mapState(authStore, ["organization", "passId"]),
  },
  methods: {
    async login() {
      try {
        await api.post(
          `/organization/${this.organization.id}/pass/${this.passId}/login`,
          {
            emailOrPhoneNumber: this.emailOrPhoneNumber,
          }
        );
        this.$router.push({
          name: "recoveryPage",
          query: {
            ...this.$route.query,
          },
        });
      } catch (e) {
        if (e.response.status === 404) {
          this.$notificationGenerator.error("No se ha encontrado el usuario");
        } else {
          this.$notificationGenerator.error(
            "Ha ocurrido un error inesperado, por favor, inténtalo de nuevo"
          );
        }
      }
    },
  },
};
</script>

<style scoped></style>
