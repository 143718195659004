<template>
  <div
    :class="[
      `spinner-icon border-transparent border-2`,
      color == 'black'
        ? `border-t-black border-l-black`
        : `border-t-white border-l-white`,
      size == 'small' ? `w-6 h-6` : `w-10 h-10`
    ]"
  ></div>
</template>

<script>
export default {
  name: 'CLoadingSpinner',
  props: {
    color: {
      type: String,
      default: 'black'
    },
    size: {
      type: String,
      default: 'small'
    }
  }
}
</script>

<style scoped>
.spinner-icon {
  animation: loading-bar-spinner 1s linear infinite;

  border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0% {
    transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
