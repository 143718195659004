<template>
  <div>
    <slot />
  </div>
</template>

<script>
import { useNotification } from "naive-ui";
import { getCurrentInstance } from "vue";

export default {
  name: "NotificationsImporter",
  data() {
    return {};
  },
  mounted() {
    let app = getCurrentInstance().appContext.app;
    let notificationGenerator = useNotification();
    notificationGenerator.changesApplied = () => {
      app.config.globalProperties.$notificationGenerator.create({
        title: this.$t("core.change-saved-title"),
        type: "success",
        content: this.$t("core.change-saved-message"),
        duration: 3500,
      });
    };
    notificationGenerator.error = (errorMessage) => {
      app.config.globalProperties.$notificationGenerator.create({
        title: this.$t("core.error-title"),
        type: "error",
        content: errorMessage || this.$t("core.error-message"),
        duration: 3500,
      });
    };

    app.config.globalProperties.$notificationGenerator = notificationGenerator;
  },
};
</script>

<style scoped></style>
