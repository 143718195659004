<template>
  <div
    class="h-full flex flex-col items-center justify-center pb-10 overflow-y-scroll"
  >
    <div class="text-secondary font-bold text-2xl">Política de privacidad</div>
    <div class="px-10 mt-10 text-secondary">
      Con este documento, queremos informarte de cuáles son los motivos por los
      que recogemos tus datos personales, qué hacemos con ellos, cuáles son tus
      derechos y de qué forma puedes ejercerlos. Si de verdad te interesa el
      destino de tus datos, te recomendamos que pierdas un par de minutos en
      leerlo. <br /><br /><b><b>¿Quién es el responsable de tus datos?</b></b
      ><br /><br />
      {{ organization.name }} <br /><br /><b
        >¿Con qué finalidad recogemos tus datos personales?</b
      ><br /><br />
      Recogemos y tratamos la información que nos facilitas con el fin de
      responder a las cuestiones que nos planteas a través de nuestros
      formularios de contacto y de comentarios, y de hacerte llegar información
      que sea de tu interés a través de nuestros canales de comunicación. Dicha
      información la mantendremos almacenada mientras no solicites su
      supresión.<br /><br />
      <b>¿Cuál es la legitimación para el tratamiento de tus datos?</b
      ><br /><br />
      Las bases legales para el tratamiento de tus datos son las siguientes:
      <br /><br />• Ejecución del contrato de suscripción para hacerte llegar
      información que sea de tu interés a través de nuestros canales de
      comunicación. <br /><br />• Obtención de tu consentimiento para responder
      a las cuestiones que nos planteas a través de nuestros formularios de
      contacto y de comentarios. Recuerda que no estás obligado a darnos tu
      consentimiento para el tratamiento de tus datos, pero de no hacerlo, no
      podremos prestarte servicio ni aceptar el registro en la plataforma.
      <br /><br />
      <b>¿A qué destinatarios se comunicarán tus datos de carácter personal?</b
      ><br /><br />
      Para poder ofrecerte nuestros servicios con una mejor calidad y cumplir
      con nuestras obligaciones, te hacemos saber que los datos que tú nos
      facilites serán cedidos a:<br /><br />
      • Comeback S.L. responsable de la plataforma Comeback.es, para una
      correcta prestación del servicio y envío de comunicaciones.<br /><br />
      <b>¿Cuáles son tus derechos cuando nos facilitas tus datos personales?</b
      ><br /><br />
      Existen una serie de derechos que puedes ejercer en cualquier momento, los
      cuales son: <br /><br />• Derechos a solicitar el acceso a tus datos de
      carácter personal.<br /><br />
      • Derecho a solicitar la rectificación o supresión. <br /><br />• Derecho
      a solicitar la limitación del tratamiento. <br /><br />• Derecho a
      oponerse al tratamiento. <br /><br />Para ejercer tus derechos, puedes
      enviar un correo al email de contacto e indicar el motivo de tu
      reclamación. También te informamos, que, si has dado tu consentimiento
      para alguna finalidad específica, tienes derecho a retirarlo en cualquier
      momento, sin que ello afecte a la licitud del tratamiento basado en el
      consentimiento previo a su retirada. Asimismo, te informamos de que puedes
      presentar una reclamación ante la Agencia Española de Protección de Datos,
      especialmente cuando no hayas obtenido satisfacción en el ejercicio de tus
      derechos.
    </div>
  </div>
</template>

<script>
import CImage from "../../../core-ui/src/components/CImage.vue";
import { mapState, mapActions } from "pinia";
import { authStore } from "@/stores/auth";

export default {
  name: "PolicyPage",
  data() {
    return {};
  },
  async mounted() {
    await this.refreshSignupInfo();
  },
  computed: {
    ...mapState(authStore, ["branding", "organization"]),
  },
  methods: {
    ...mapActions(authStore, ["refreshSignupInfo"]),
  },
  components: {
    CImage,
  },
};
</script>

<style scoped></style>
