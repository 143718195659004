<template>
  <div>
    <div class="w-10/12 m-auto">
      <delivery-code-field v-model:value="deliveryCode" :compressed="false" />
    </div>
    <div class="flex flex-row items-center justify-center mt-12">
      <n-button
        type="primary"
        :loading="loading"
        size="large"
        :disabled="!deliveryCode"
        @click="addPoints"
        >¡Sumar puntos!</n-button
      >
    </div>
  </div>
</template>
<script>
import { mapState } from "pinia";
import { authStore } from "@/stores/auth";
import CImage from "../../../core-ui/src/components/CImage.vue";
import DeliveryCodeField from "./DeliveryCodeField.vue";
import api from "@/api.js";
import { useNotification } from "naive-ui";

export default {
  name: "AddPointsPage",
  data() {
    return {
      deliveryCode: null,
      loading: false,
      notificationGenerator: null,
    };
  },
  mounted() {
    this.notificationGenerator = useNotification();
    if (!this.currentCustomer?.id) {
      this.$router.replace({ name: "signupPage" });
    }
  },
  methods: {
    async addPoints() {
      this.loading = true;
      try {
        await api.post("/deliveryCode", {
          deliveryCode: this.deliveryCode,
          customerId: this.currentCustomer.id,
        });
        this.notificationGenerator.create({
          title: "Puntos sumados correctamente!",
          type: "success",
          content: "Los puntos se han sumado a su perfil.",
          duration: 3500,
        });
      } catch (e) {
        if (e.response.data.message === "Order not found") {
          this.notificationGenerator.create({
            title: "Error al sumar puntos",
            type: "error",
            content: "No se encuentra un pedido con ese código.",
            duration: 3500,
          });
        }
        else if (e.response.data.message === "Order already added") {
          this.notificationGenerator.create({
            title: "Error al sumar puntos",
            type: "error",
            content: "El pedido ya ha sido agregado.",
            duration: 3500,
          });
        } else {

          this.notificationGenerator.create({
            title: "Error al sumar puntos",
            type: "error",
            content: "El código de entrega no es válido.",
            duration: 3500,
          });
        }
      }
      this.loading = false;
    },
  },
  computed: {
    ...mapState(authStore, ["branding", "currentCustomer"]),
  },
  components: {
    CImage,
    DeliveryCodeField,
  },
};
</script>

<style scoped>
.floatting-bottom {
  position: fixed;
  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
