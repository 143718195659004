<template>
  <n-config-provider :theme-overrides="themeOverrides">
    <n-notification-provider placement="top">
      <n-dialog-provider>
        <notifications-importer>
          <div :style="cssProps">
            <RouterView />
          </div>
        </notifications-importer>
      </n-dialog-provider>
    </n-notification-provider>
  </n-config-provider>
</template>

<script>
import { RouterView } from "vue-router";
import { NConfigProvider } from "naive-ui";
import { mapStores } from "pinia";
import { authStore } from "@/stores/auth";
import { useHead } from "@vueuse/head";
import { computed } from "vue";
import NotificationsImporter from "@/components/NotificationsImporter.vue";

export default {
  name: "App",
  mounted() {
    useHead({
      title: computed(() => this.siteData.title),
      link: computed(() => this.siteData.link),
    });
  },
  computed: {
    ...mapStores(authStore),
    /**
     * Use this for type hints under js file
     * @type import('naive-ui').GlobalThemeOverrides
     */
    buttonTextColor() {
      return this.colorIsDark(this.authStore.branding.accentColor)
        ? "#FFFFFF"
        : "#000000";
    },
    themeOverrides() {
      return {
        common: {
          primaryColor: this.authStore.branding.accentColor,
          primaryColorPressed: this.darken(this.authStore.branding.accentColor),
          primaryColorHover: this.darken(
            this.authStore.branding.accentColor,
            -10
          ),
          textColor: this.authStore.branding.secondaryColor,
        },
        Input: {
          caretColor: "#000000",
        },
        Form: {
          labelTextColor: this.authStore.branding.secondaryColor,
        },
        Checkbox: {
          colorChecked: this.authStore.branding.accentColor,
          checkMarkColor: this.colorIsDark(this.authStore.branding.accentColor)
            ? "#FFFFFF"
            : "#000000",
          textColor: this.authStore.branding.accentColor,
        },
        Dialog: {
          iconColorWarning: this.authStore.branding.accentColor,
        },
        Button: {
          textColorPrimary: this.buttonTextColor,
          textColorHoverPrimary: this.buttonTextColor,
          textColorPressedPrimary: this.buttonTextColor,
          textColorFocusPrimary: this.buttonTextColor,
          colorWarning: this.authStore.branding.accentColor,
          colorHoverWarning: this.darken(this.authStore.branding.accentColor),
          colorPressedWarning: this.darken(
            this.authStore.branding.accentColor,
            -10
          ),
          colorFocusWarning: this.darken(this.authStore.branding.accentColor),
          borderWarning: "0px solid #000",
          borderHoverWarning: "0px solid #000",
          borderPressedWarning: "0px solid #000",
        },
      };
    },
    cssProps() {
      return {
        "--accentColor": this.authStore.branding?.accentColor || "#3ED3E0",
        "--secondaryColor":
          this.authStore.branding?.secondaryColor || "#545383",
      };
    },
    siteData() {
      return {
        title: this.organizationName,
        link: [
          {
            rel: "icon",
            href: this.faviconUrl,
          },
        ],
      };
    },
    organizationName() {
      return this.authStore.organization?.name || "Sign up";
    },
    faviconUrl() {
      let logoImageId = this.authStore.branding?.logoImageId;
      if (!logoImageId) return null;
      return `https://res.cloudinary.com/comebackapp/image/upload/h_32,w_32,f_auto,q_auto/v1560251370/${logoImageId}`;
    },
  },
  methods: {
    darken(color, percent = 10) {
      if (!color) return color;
      var R = parseInt(color.substring(1, 3), 16);
      var G = parseInt(color.substring(3, 5), 16);
      var B = parseInt(color.substring(5, 7), 16);
      R = Math.min(parseInt((R * (100 - percent)) / 100), 255);
      G = Math.min(parseInt((G * (100 - percent)) / 100), 255);
      B = Math.min(parseInt((B * (100 - percent)) / 100), 255);

      var RR =
        R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
      var GG =
        G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
      var BB =
        B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

      return "#" + RR + GG + BB;
    },
    colorIsDark(hexcolor) {
      if (!hexcolor) return false;
      let color = hexcolor.substr(1, 6);
      let r = parseInt(color.substring(0, 2), 16);
      let g = parseInt(color.substring(2, 4), 16);
      let b = parseInt(color.substring(4, 6), 16);
      let yiq = (r * 299 + g * 587 + b * 114) / 1000;
      return yiq < 200;
    },
  },
  components: {
    RouterView,
    NConfigProvider,
    NotificationsImporter,
  },
};
</script>

<style scoped></style>
