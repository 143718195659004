<template>
  <div>
    <c-collapse>
      <template v-slot:trigger>
        <div class="flex flex-row justify-between items-center w-full">
          <div class="text-secondary underline">
            ¿Tienes un identificador de referido?
          </div>
        </div>
      </template>
      <div class="flex flex-col w-full">
        <div class="flex flex-row items-center">
          <div class="mb-4 mt-2 flex-1">
            <n-input
              placeholder="Identificador del referido"
              :value="value"
              @update:value="(value) => $emit('update:value', value)"
            />
          </div>
          <div>
            <c-icon
              @click="showInfo = true"
              name="InfoOutlined"
              class="ml-2 mb-2 text-accent cursor-pointer"
            />
          </div>
        </div>
      </div>
    </c-collapse>

    <n-drawer v-model:show="showInfo" :height="150" placement="top">
      <n-drawer-content title="Código de referido">
        <div class="">
          Si vienes recomendado por un amigo, puedes introducir su código de
          referido, el cual puedes encontrar en los detalles de su tarjeta.
        </div>
      </n-drawer-content>
    </n-drawer>
  </div>
</template>

<script>
import CIcon from "../../../core-ui/src/components/CIcon.vue";
import CCollapse from "../../../core-ui/src/components/CCollapse.vue";

export default {
  name: "ReferralCodeField",
  props: {
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      showInfo: false,
    };
  },
  methods: {},
  components: {
    CIcon,
    CCollapse,
  },
};
</script>

<style scoped></style>
