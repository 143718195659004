<template>
  <div
    class="z-50 fixed top-0 left-0 right-0 bottom-0 h-screen w-full bg-black backdrop-blur-md bg-opacity-50 flex flex-row items-center justify-center pointer-events-none"
  >
    <c-loading-spinner v-if="showSpinner" color="white" size="medium" />
  </div>
</template>

<script>
import CLoadingSpinner from '../../../core-ui/src/components/CLoadingSpinner.vue'

export default {
  name: 'CLoadingScreen',
  data() {
    return {}
  },
  props: {
    showSpinner: {
      type: Boolean,
      default: true
    }
  },
  methods: {},
  components: {
    CLoadingSpinner
  }
}
</script>

<style scoped></style>
