<template>
  <n-form ref="formRef" :model="signupInfo" :rules="predefinedRules">
    <n-form-item v-if="!$route.query.name" path="name" label="Nombre">
      <n-input
        @keydown.enter.prevent
        v-model:value="internalSignupInfo.name"
        placeholder="Escribe tu nombre aquí"
      />
    </n-form-item>
    <n-form-item v-if="!$route.query.name" path="surname" label="Apellidos">
      <n-input
        @keydown.enter.prevent
        v-model:value="internalSignupInfo.surname"
        placeholder="Escribe tus apellidos aquí"
      />
    </n-form-item>
    <n-form-item path="email" label="Email">
      <n-input
        @keydown.enter.prevent
        v-model:value="internalSignupInfo.email"
        placeholder="Escribe tu email aquí"
      />
    </n-form-item>
    <n-form-item
      v-if="!$route.query.phoneNumber"
      path="phoneNumber"
      label="Número de teléfono"
    >
      <vue-tel-input
        v-model="internalSignupInfo.phoneNumber"
        :inputOptions="{ placeholder: 'Número de teléfono' }"
        :mode="'international'"
        :styleClasses="['w-full', 'h-9']"
      />
    </n-form-item>
    <n-form-item
      v-if="!$route.query.birthDate"
      path="birthDate"
      label="Fecha de cumpleaños"
    >
      <c-date-picker
        v-model:value="internalSignupInfo.birthDate"
        placeholder="Indica tu fecha de nacimiento"
        :max="currentDate"
      />
    </n-form-item>
  </n-form>
  <custom-fields-form
    v-model:customerCustomFields="internalSignupInfo.customerCustomFields"
    :custom-fields="customFields"
  />
</template>

<script>
import CustomFieldsForm from "./CustomFieldsForm.vue";
import CDatePicker from "../../../core-ui/src/components/CDatePicker.vue";
import moment from "moment";

export default {
  name: "LegacyCustomerFields",
  data() {
    return {};
  },
  props: {
    signupInfo: {
      type: Object,
      default: () => {},
    },
    predefinedRules: {
      type: Object,
      default: () => {},
    },
    customFields: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    validate() {
      return this.$refs.formRef.validate();
    },
  },
  computed: {
    currentDate() {
      return moment().subtract(1, "year").format("YYYY-MM-DD");
    },
    internalSignupInfo: {
      get() {
        return this.signupInfo;
      },
      set(value) {
        this.$emit("update:signupInfo", value);
      },
    },
  },
  components: {
    CustomFieldsForm,
    CDatePicker,
  },
};
</script>

<style scoped></style>
