import { createApp, markRaw } from "vue";
import { createPinia } from "pinia";
import naive from "naive-ui";
import App from "./App.vue";
import i18n from "@/i18n.js";
import router from "./router";
import { createHead } from "@vueuse/head";
import utils from "../../core/src/utils";
import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";
import { authStore } from "@/stores/auth";
import metaPixel from "./metaPixel";

import "./style.css";

const app = createApp(App);
app.config.globalProperties.$utils = utils;
const head = createHead();

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

app.use(head);
app.use(pinia);
app.use(router);
app.use(naive);
app.use(i18n);
app.use(VueTelInput);

const meta = document.createElement("meta");
meta.name = "naive-ui-style";
document.head.appendChild(meta);

const authStoreEntity = authStore();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register(
      import.meta.env.MODE === "production"
        ? "/serviceWorker.js"
        : "/dev-sw.js?dev-sw"
    )
    .then(async (registration) => {
      await registration.update();
      authStoreEntity.updateServiceWorkerRegistration(registration);
      console.log("Service worker registered");
    });
}

app.mount("#app");

if (authStoreEntity.organizationConfig.metaPixelId) {
  metaPixel.load(authStoreEntity.organizationConfig.metaPixelId, router);
}
