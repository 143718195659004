import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";

export const locales = ["en", "es", "ca", "de"];

export default createI18n({
  legacy: true,
  globalInjection: true,
  locale: import.meta.env.VITE_I18N_LOCALE || "es",
  fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE || "es",
  messages: messages,
});
