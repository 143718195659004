<template>
  <div v-if="background" :style="style">
    <slot></slot>
  </div>
  <img v-else :src="url" />
</template>

<script>
export default {
  name: 'CImage',
  props: {
    background: {
      type: Boolean,
      default: false
    },
    asset: {
      type: String,
      default: null
    },
    imageId: {
      type: String,
      default: null
    },
    local: {
      type: Boolean,
      default: false
    },
    mode: {
      type: String,
      default: 'fill'
    },
    grayscale: {
      type: Boolean,
      default: false
    },
    colorize: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    height: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      internalWidth: 0,
      internalHeight: 0
    }
  },
  created() {
    this.internalWidth = this.width || 0
    this.internalHeight = this.height || 0
  },
  mounted() {
    this.internalWidth = this.width || this.$el.clientWidth
    this.internalHeight = this.height || this.$el.clientHeight
  },
  computed: {
    fetchUrl() {
      let assetPath = require(`./assets/${this.asset}`)
      if (process.env.NODE_ENV !== 'production' || this.local) {
        return assetPath
      }
      let cloudinaryFetch =
        'https://res.cloudinary.com/comeback/image/fetch/f_auto,q_auto/'
      let assetUrl = process.env.VUE_APP_BASE_URL + assetPath
      return cloudinaryFetch + assetUrl
    },
    url() {
      if (this.internalWidth === 0 || this.internalHeight === 0) return ''
      if (this.asset) return this.fetchUrl
      if (this.imageId) {
        return `https://res.cloudinary.com/comebackapp/image/upload/f_auto,q_auto${
          this.modifier
        }h_${this.internalHeight * 2},w_${this.internalWidth * 2},c_${
          this.mode
        }/v1664623844/${this.imageId}`
      }
      return null
    },
    modifier() {
      if (this.grayscale) {
        return '/e_grayscale/'
      } else if (this.colorize) {
        return `/e_colorize,co_rgb:${this.colorize.replace('#', '').trim()}/`
      } else {
        return '/'
      }
    },
    style() {
      if (this.url) {
        return { backgroundImage: `url(${this.url})` }
      } else {
        return null
      }
    }
  }
}
</script>
