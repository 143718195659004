<template>
  <div>
    <div class="flex flex-col items-center justify-center">
      <div v-if="!isFacebook">
        <div class="px-4 pb-12 mx-8 rounded-xl">
          <div class="text-secondary text-2xl text-center">
            ¡Ya puedes descargar tu tarjeta!
          </div>
          <div class="mt-10" v-if="downloadUrl">
            <div class="flex flex-row items-center justify-center">
              <div v-if="deviceIsIphone" class="flex flex-col items-center">
                <img
                  @click="handleAppleDownload"
                  class="w-40 mb-5"
                  src="../assets/apple-wallet.svg"
                />
                <c-loading-spinner v-if="loading" />
              </div>
              <div v-else @click="handleAndroidDownload">
                <img class="w-40" src="../assets/google-wallet.svg" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="!deviceIsIphone" class="flex flex-col items-center">
          <div class="text-secondary text-lg mt-10 mx-8 text-center">
            Si no tienes la app de Google Wallet, puedes utilizar una aplicación
            alternativa:
          </div>
          <img
            @click="handleWalletPassDownload"
            class="w-36 mt-8"
            src="../assets/wallet-passes.png"
          />
        </div>
      </div>
      <div v-else class="text-center text-secondary mt-3">
        <div class="text-2xl">¡Registro completado!</div>
        <div class="mt-7 text-lg">
          Puedes descargar tu tarjeta en el email que te acabamos de enviar
        </div>
        <div class="mt-7 text-lg">¡Gracias!</div>
      </div>
    </div>
  </div>
  <c-loading-screen v-if="waitingNotificationsApproval" :show-spinner="false" />
</template>

<script>
import { mapStores, mapState, mapActions } from "pinia";
import { authStore } from "@/stores/auth";
import { useNotification } from "naive-ui";
import CLoadingSpinner from "../../../core-ui/src/components/CLoadingSpinner.vue";
import CLoadingScreen from "../../../core-ui/src/components/CLoadingScreen.vue";
import api from "@/api.js";

export default {
  name: "DownloadPage",
  data() {
    return {
      notificationCreator: null,
      downloadUrl: null,
      loading: false,
      waitingNotificationsApproval: false,
    };
  },
  async mounted() {
    if (!this.customerId) {
      this.$router.replace({ name: "signupPage" });
    }
    let locationId = this.authStore.locationsBySlug[this.slugName]?.id;
    if (locationId) {
      await api.post(`/customer/${this.customerId}/detected`, {
        locationId,
      });
    }
    this.notificationCreator = useNotification();
    try {
      this.downloadUrl = (
        await api.get(
          `/pass/${this.passId}/customer/${this.customerId}/downloadAndroidPassUrl`
        )
      ).data.downloadUrl;
    } catch (e) {
      if (e.response?.status === 409) {
        this.removeCustomerInfoFromOrganization(this.organization.id);
        this.$router.replace({ name: "signupPage" });
      }
    }
  },
  computed: {
    ...mapStores(authStore),
    ...mapState(authStore, [
      "passId",
      "serviceWorkerRegistration",
      "organization",
    ]),
    isFacebook() {
      return window.navigator.userAgent.includes("FB");
    },
    customerId() {
      return this.authStore.currentCustomer?.id;
    },
    deviceIsIphone() {
      return [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform);
    },
    applePassUrl() {
      return `${import.meta.env.VITE_API_BASE_URL}/pass/${
        this.passId
      }/customer/${this.customerId}/downloadApplePass`;
    },
    slugName() {
      return this.$route.params.slugName;
    },
  },
  components: {
    CLoadingSpinner,
    CLoadingScreen,
  },
  methods: {
    ...mapActions(authStore, ["removeCustomerInfoFromOrganization"]),
    async handleAndroidDownload() {
      await this.handlePushSubscription();
      await api.put(`/customer/${this.customerId}/trackAndroidDownload`);
      window.open(this.downloadUrl, "_blank");
    },
    async handlePushSubscription() {
      if (!(await this.askForNotifications())) return;
      let pushSubscription =
        await this.serviceWorkerRegistration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey: import.meta.env
            .VITE_APPLICATION_SERVER_KEY_PUBLIC,
        });
      await api.post(`/customer/${this.customerId}/savePushSubscription`, {
        pushSubscription,
      });
    },
    async askForNotifications() {
      if (this.deviceIsIphone) return false;
      this.waitingNotificationsApproval = true;
      let result = await Notification.requestPermission();
      this.waitingNotificationsApproval = false;
      return result === "granted";
    },
    handleAppleDownload() {
      this.loading = true;
      var req = new XMLHttpRequest();
      req.open("GET", this.applePassUrl, true);
      req.responseType = "blob";
      let that = this;
      req.onload = function () {
        var blob = req.response;
        var link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.click();
        that.loading = false;
      };
      req.send();
    },
    handleWalletPassDownload() {
      window.open(
        `https://walletpass.io?u=${encodeURIComponent(this.applePassUrl)}`,
        "_blank"
      );
    },
  },
};
</script>

<style scoped></style>
