<template>
  <div>
    <div
      v-for="field in customFields"
      :key="field.id"
      :class="{ hidden: tweakedCustomFields[field.id]?.hidden ?? field.hidden }"
      class="mb-5"
    >
      <div v-if="field.type === 'select'">
        <div v-if="field.options?.length > 0">
          <div class="text-secondary mb-1">
            {{ field.name }}
          </div>
          <n-select
            @update:value="
              (value) =>
                $emit('update:customerCustomFields', {
                  ...internalCustomFields,
                  [field.id]: value,
                })
            "
            :value="internalCustomFields[field.id]"
            :placeholder="field.name"
            :options="
              field.options.map((o) => {
                return { label: o, value: o };
              })
            "
          />
        </div>
      </div>
      <div v-else-if="field.type === 'string'">
        <div class="text-secondary mb-1">
          {{ field.name }}
        </div>
        <n-input
          @update:value="
            (value) =>
              $emit('update:customerCustomFields', {
                ...internalCustomFields,
                [field.id]: value,
              })
          "
          :value="internalCustomFields[field.id]"
          :placeholder="field.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CustomFieldsForm",
  data() {
    return {
      internalCustomFields: { ...this.customerCustomFields },
    };
  },
  props: {
    customFields: {
      type: Array,
      default: () => [],
    },
    customerCustomFields: {
      type: Object,
      default: () => {},
    },
  },
  methods: {},
  mounted() {
    Object.entries(this.tweakedCustomFields).forEach(
      ([customFieldKey, customFieldData]) => {
        if (customFieldData.value) {
          this.internalCustomFields[customFieldKey] = customFieldData.value;
        }
      }
    );
    this.$emit("update:customerCustomFields", this.internalCustomFields);
  },
  computed: {
    aux() {
      return this.$route.query;
    },
    tweakedCustomFields() {
      return Object.entries(this.$route.query).reduce((acc, [key, value]) => {
        if (key.includes("customField.")) {
          let customFieldKey = key.replace("customField.", "");
          let customFieldData = JSON.parse(value);
          acc[customFieldKey] = customFieldData;
        }
        return acc;
      }, {});
    },
  },
  watch: {
    customerCustomFields: {
      handler() {
        this.internalCustomFields = { ...this.customerCustomFields };
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
